<template>
  <div class="w-100 mb-3">
    <div v-background-2 class="w-100 rounded p-3 mb-1 shadow-sm">
      <div class="mb-3 d-flex justify-content-between align-items-center">
        <div class="w-100">
          <h5 class="m-0">Satisfaction client</h5>
          <small>Note sur 4 points</small>
        </div>
        <div class="w-100">
          <base-select
            inputText="Groupe"
            v-model.number="societe_id"
            :options="options.societes"
            :modeIOS="true"
            :disabled="false"
            @input="satisfN"
          ></base-select>
        </div>
      </div>
      <div class="d-flex justify-content-between w-100">
        <ContainerCard class="w-100 mr-3">
          <apexchart
            v-if="result.length > 0"
            type="line"
            height="250"
            :options="chartOptions"
            :series="result"
          ></apexchart>
        </ContainerCard>
      </div>
    </div>
    <container-list-extend title="" class="d-flex flex-column w-100">
      <template v-slot:title>
        <div class="flex-grow-1 pl-1">Informations par pilote</div>
      </template>
      <template v-slot:form>
        <div class="p-3">
          <div>
            <h6>Détails des remontées d'informations par pilote</h6>
          </div>
          <div class="mb-3">
            <base-select
            inputText="Année"
            v-model.number="date_pilote"
            :options="optionsDatePilote"
            :modeIOS="true"
            :disabled="false"
          ></base-select>
          </div>
          <div v-for="user in satisfByUsers" :key="user.user_id">
            <div class="d-flex justify-content-between align-items-center pl-3 pr-3">

              <div>
                <UserViewer :userId="user.user_id" v-if="user.user_id" :show-text="true" class="mb-3"></UserViewer>
              </div>
              <div>{{ user.counter }} retour(s) client</div>
            </div>
          </div>
        </div>
      </template>
    </container-list-extend>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
//import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend2.vue";
//import DescriptionFromList from "@/components/bases/DescriptionFromList";
import UserViewer from "@/components/application/UserViewer.vue";
import BaseSelect from "@/components/bases/Select.vue";
import ContainerCard from "@/components/containers/ContainerCard.vue";

export default {
  components: {
    //BaseIcon,
    ContainerListExtend,
    //DescriptionFromList,
    UserViewer,
    BaseSelect,
    ContainerCard,
  },
  data() {
    return {
      loading: false,
      data: [],
      societe_id: null,
      result: [],
      counters: {},
      date_pilote: this.$moment().year(),
      optionsDatePilote: [
        {
          id: this.$moment().year(),
          description: this.$moment().year(),
        },
        {
          id: this.$moment().year()-1,
          description: this.$moment().year()-1,
        },
        {
          id: this.$moment().year()-2,
          description: this.$moment().year()-2,
        }
      ]
    };
  },
  methods: {
    ...mapActions({}),
    getSatisfactions: function () {
      this.loading = true;
      this.$http
        .get("/crm/satisfaction")
        .then((response) => {
          this.data = response.data;
          this.satisfN();
        })
        .finally(() => (this.loading = false));
    },
    satisfN: function () {
      if (!this.data) this.result = [];

      const dt = this.$moment().year();

      let counter = 0;
      let counter2 = 0;
      let counter3 = 0;
      let moyCommerceN = 0;
      let moyCommerceN1 = 0;
      let moyCommerceN2 = 0;
      let moyPilotageN = 0;
      let moyPilotageN1 = 0;
      let moyPilotageN2 = 0;
      let moyPrixN = 0;
      let moyPrixN1 = 0;
      let moyPrixN2 = 0;
      let moyQsserN = 0;
      let moyQsserN1 = 0;
      let moyQsserN2 = 0;
      let moyTechniqueN = 0;
      let moyTechniqueN1 = 0;
      let moyTechniqueN2 = 0;

      let D = [];

      if (this.societe_id) {
        D = this.data.filter((x) => x.affaire.affaire_societe_id == this.societe_id);
      } else {
        D = this.data;
      }

      D.forEach((d) => {
        if (d.affaire && this.$moment(d.satisfaction_date).year() == dt) {
          if (d.satisfaction_note_commerce > 0) moyCommerceN = moyCommerceN + d.satisfaction_note_commerce;
          if (d.satisfaction_note_pilotage > 0) moyPilotageN = moyPilotageN + d.satisfaction_note_pilotage;
          if (d.satisfaction_note_prix > 0) moyPrixN = moyPrixN + d.satisfaction_note_prix;
          if (d.satisfaction_note_qsser > 0) moyQsserN = moyQsserN + d.satisfaction_note_qsser;
          if (d.satisfaction_note_technique > 0) moyTechniqueN = moyTechniqueN + d.satisfaction_note_technique;
          counter++;
        }

        if (d.affaire && this.$moment(d.satisfaction_date).year() == dt - 1) {
          if (d.satisfaction_note_commerce > 0) moyCommerceN1 = moyCommerceN1 + d.satisfaction_note_commerce;
          if (d.satisfaction_note_pilotage > 0) moyPilotageN1 = moyPilotageN1 + d.satisfaction_note_pilotage;
          if (d.satisfaction_note_prix > 0) moyPrixN1 = moyPrixN1 + d.satisfaction_note_prix;
          if (d.satisfaction_note_qsser > 0) moyQsserN1 = moyQsserN1 + d.satisfaction_note_qsser;
          if (d.satisfaction_note_technique > 0) moyTechniqueN1 = moyTechniqueN1 + d.satisfaction_note_technique;
          counter2++;
        }

        if (d.affaire && this.$moment(d.satisfaction_date).year() == dt - 2) {
          if (d.satisfaction_note_commerce > 0) moyCommerceN2 = moyCommerceN2 + d.satisfaction_note_commerce;
          if (d.satisfaction_note_pilotage > 0) moyPilotageN2 = moyPilotageN2 + d.satisfaction_note_pilotage;
          if (d.satisfaction_note_prix > 0) moyPrixN2 = moyPrixN2 + d.satisfaction_note_prix;
          if (d.satisfaction_note_qsser > 0) moyQsserN2 = moyQsserN2 + d.satisfaction_note_qsser;
          if (d.satisfaction_note_technique > 0) moyTechniqueN2 = moyTechniqueN2 + d.satisfaction_note_technique;
          counter3++;
        }
      });

      //if (counter === 0) counter = 1;
      //if (counter2 === 0) counter2 = 1;
      //if (counter3 === 0) counter3 = 1;

      moyCommerceN = (moyCommerceN / counter).toFixed(1);
      moyPilotageN = (moyPilotageN / counter).toFixed(1);
      moyPrixN = (moyPrixN / counter).toFixed(1);
      moyQsserN = (moyQsserN / counter).toFixed(1);
      moyTechniqueN = (moyTechniqueN / counter).toFixed(1);

      moyCommerceN1 = (moyCommerceN1 / counter2).toFixed(1);
      moyPilotageN1 = (moyPilotageN1 / counter2).toFixed(1);
      moyPrixN1 = (moyPrixN1 / counter2).toFixed(1);
      moyQsserN1 = (moyQsserN1 / counter2).toFixed(1);
      moyTechniqueN1 = (moyTechniqueN1 / counter2).toFixed(1);

      moyCommerceN2 = (moyCommerceN2 / counter3).toFixed(1);
      moyPilotageN2 = (moyPilotageN2 / counter3).toFixed(1);
      moyPrixN2 = (moyPrixN2 / counter3).toFixed(1);
      moyQsserN2 = (moyQsserN2 / counter3).toFixed(1);
      moyTechniqueN2 = (moyTechniqueN2 / counter3).toFixed(1);

      this.counters = [counter, counter2, counter3];

      this.result = [
        {
          name: "Commerce",
          data: [moyCommerceN2, moyCommerceN1, moyCommerceN],
          color: "#dc3545",
        },
        {
          name: "Pilotage",
          data: [moyPilotageN2, moyPilotageN1, moyPilotageN],
          color: "#6610f2",
        },
        {
          name: "Prix",
          data: [moyPrixN2, moyPrixN1, moyPrixN],
          color: "#007bff",
        },
        {
          name: "QSSER",
          data: [moyQsserN2, moyQsserN1, moyQsserN],
          color: "#28a745",
        },
        {
          name: "Technique",
          data: [moyTechniqueN2, moyTechniqueN1, moyTechniqueN],
          color: "#ffc107",
        },
      ];
    },
  },
  mounted() {
    this.getSatisfactions();
  },
  computed: {
    ...mapGetters({
      options: "affaires/data",
    }),
    ...mapMultiRowFields("affaires", ["affaires"]),
    ...mapMultiRowFields("user", ["users"]),
    satisCounter: function () {
      return this.data.length;
    },
    satisfByUsers: function () {
      let arr = [];
      let result = [];
      this.data.forEach((d) => {
        if(this.$moment(d.satisfaction_date).year() == this.date_pilote)
        arr.push(d.satisfaction_user_id)
      });
      this.users.forEach((user) => {
        let counter = 0;
        arr.forEach((a) => {
          if (a == user.id) {
            counter++;
          }
        });

        if (counter > 0) {
          result.push({
            user_id: user.id,
            avatar: user.collaborateur_avatar,
            name: user.collaborateur_nom + " " + user.collaborateur_prenom,
            counter: counter,
          });
        }
      });
      return result;
    },
    moy_satisfaction_note_commerce: function () {
      let moy = 0;
      let counter = 0;
      this.data.forEach((d) => {
        if (d.satisfaction_note_commerce > 0) {
          moy = moy + d.satisfaction_note_commerce;
          counter++;
        }
      });
      return (moy / counter).toFixed(1);
    },
    moy_satisfaction_note_pilotage: function () {
      let moy = 0;
      let counter = 0;
      this.data.forEach((d) => {
        if (d.satisfaction_note_pilotage > 0) {
          moy = moy + d.satisfaction_note_pilotage;
          counter++;
        }
      });
      return (moy / counter).toFixed(1);
    },
    moy_satisfaction_note_prix: function () {
      let moy = 0;
      let counter = 0;
      this.data.forEach((d) => {
        if (d.satisfaction_note_prix > 0) {
          moy = moy + d.satisfaction_note_prix;
          counter++;
        }
      });
      return (moy / counter).toFixed(1);
    },
    moy_satisfaction_note_qsser: function () {
      let moy = 0;
      let counter = 0;
      this.data.forEach((d) => {
        if (d.satisfaction_note_qsser > 0) {
          moy = moy + d.satisfaction_note_qsser;
          counter++;
        }
      });
      return (moy / counter).toFixed(1);
    },
    moy_satisfaction_note_technique: function () {
      let moy = 0;
      let counter = 0;
      this.data.forEach((d) => {
        if (d.satisfaction_note_technique > 0) {
          moy = moy + d.satisfaction_note_technique;
          counter++;
        }
      });
      return (moy / counter).toFixed(1);
    },
    chartOptions() {
      if (this.result) {
        return {
          colors: this.result.map((x) => x.color),
          chart: {
            background: "transparent",
            toolbar: {
              show: false,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
              autoSelected: "zoom",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          theme: {
            mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
          },
          stroke: {
            width: 4,
            curve: "smooth",
          },
          xaxis: {
            categories: [
              [this.$moment().year() - 2, " (" + this.counters[2] + " retours)"],
              [this.$moment().year() - 1, " (" + this.counters[1] + " retours)"],
              [this.$moment().year(), " (" + this.counters[0] + " retours)"],
            ],
          },
          yaxis: {
            max: 4,
          },
          fill: {
            opacity: 0.8,
          },
          markers: {
            size: 4,
            strokeColors: "#fff",
            strokeWidth: 0,
            hover: {
              size: 7,
            },
          },
          legend: {
            show: true,
            position: "right",
            showForZeroSeries: false,
            itemMargin: {
              horizontal: 0,
              vertical: 3,
            },
            markers: {
              width: 10,
              height: 10,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " /4";
              },
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                legend: {
                  position: "bottom",
                  horizontalAlign: "left",
                  itemMargin: {
                    horizontal: 20,
                    vertical: 5,
                  },
                  markers: {
                    width: 10,
                    height: 10,
                  },
                  showForZeroSeries: false,
                },
              },
            },
          ],
        };
      }
      return [];
    },
  },
};
</script>
<style></style>
